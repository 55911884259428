import React from "react";

const Stemalczyk = () => {
  return (
    <div className="quote">
      <p>Są takie związki duszy i ciała, o których się filozofom nie śniło.</p>
      <span className="author">T. Stelmaszczyk</span>
    </div>
  );
};

export default Stemalczyk;
