import React, { useRef } from "react";
import Header from "../components/Header";
import About from "./About";
import Contact from "./Contact";
import Footer from "../components/Footer";

const Home = () => {
  const refHome = useRef(null);
  const refAbout = useRef(null);
  const refContact = useRef(null);

  return (
    <>
      <div className="home" ref={refHome}>
        <Header refHome={refHome} refAbout={refAbout} refContact={refContact} />
        <div className="home__header">
          <h1 className="home__h1">Violetta Borowska</h1>
          <h2 className="home__h2 firm__name">Theoforos</h2>
          <hr className="home__hr" />
          <h2 className="home__h2">PSYCHOTERAPEUTA</h2>
        </div>
      </div>
      <About refAbout={refAbout} />
      <Contact refContact={refContact} />
      <Footer />
    </>
  );
};
export default Home;
