import Footer from "../components/Footer";
import { ExternalLink } from 'react-external-link';

const Rodo = () => {
    return (
        <>
            <div className="rodo">
                <h1 className="rodo__title">Polityka Prywatności</h1>
                <hr className="home__hr" />
                <h2 className="rodo__h2">Wprowadzenie</h2>
                <ol className="rodo__list">
                    <li>
                        Prywatność odwiedzających naszą stronę internetową jest dla nas bardzo ważna i dokładamy wszelkich starań,
                        aby ją chronić. Niniejsza polityka wyjaśnia, co robimy z Twoimi danymi osobowymi.
                    </li>
                    <li>
                        Zgoda na korzystanie z plików cookie zgodnie z warunkami niniejszej polityki podczas pierwszej wizyty na naszej
                        stronie pozwala nam na korzystanie z plików cookie przy każdej kolejnej wizycie na naszej stronie.
                    </li> 
                </ol>
                <h2 className="rodo__h2">Żródło</h2>
                <p className="rodo__text">
                    Ten dokument został utworzony przy użyciu szablonu firmy SEQ Legal (seqlegal.com)
                    i zmodyfikowany dla celów Website Planet 
                    <ExternalLink href="www.websiteplanet.com" className="rodo__link">www.websiteplanet.com</ExternalLink>
                </p>
                <h2 className="rodo__h2">Zbieranie danych osobowych</h2>
                <p  className="rodo__text">
                    Następujące rodzaje danych osobowych mogą być gromadzone, przechowywane i wykorzystywane:
                </p>
                <ol className="rodo__list">
                    <li>
                        Informacje o komputerze, w tym adres IP, lokalizacja geograficzna, typ i wersja przeglądarki oraz
                        system operacyjny.
                    </li>
                    <li>
                        Informacje o Twoich wizytach i korzystaniu z tej witryny, w tym źródło odesłań, długość wizyty,
                        wyświetlenia stron i ścieżki nawigacji w witrynie.
                    </li>
                    <li>
                        Informacje, które są generowane podczas korzystania z naszej strony internetowej, w tym kiedy, jak często
                        i w jakich okolicznościach z niej korzystasz.
                    </li>
                    <li>
                        Wszelkie inne dane osobowe, które nam przesyłasz.
                    </li>
                </ol>
                <p className="rodo__text">
                    Zanim ujawnisz nam dane osobowe innej osoby, musisz uzyskać zgodę tej osoby na ujawnienie i przetwarzanie tych
                    danych osobowych zgodnie z niniejszymi zasadami.
                </p>
                <h2 className="rodo__h2">Bezpieczeństwo danych osobowych</h2>
                <ol className="rodo__list">
                    <li>
                        Podejmiemy zasadne techniczne i organizacyjne środki ostrożności w celu przeciwdziałania utracie,
                        nadużyciu lub zmianie danych osobowych użytkownika.
                    </li>
                    <li>
                        Będziemy przechowywać wszystkie dane osobowe, które podasz na naszych bezpiecznych serwerach
                        (chronionych hasłem i zaporą).
                    </li>
                </ol>
                <h2 className="rodo__h2">Nowelizacje</h2>
                <p className="rodo__text">
                    Niniejsze zasady mogą być okresowo aktualizowane poprzez zamieszczenie w naszej witrynie ich nowej wersji.
                    Należy od czasu do czasu sprawdzać tę stronę, aby upewnić się, że rozumiesz wszelkie zmiany w tych zasadach.
                </p>
                <h2 className="rodo__h2">Strony Internetowe osób trzecich</h2>
                <p className="rodo__text">
                    Nasza strona internetowa zawiera hiperłącza do stron internetowych osób trzecich oraz szczegółowe
                    informacje na ich temat. Nie mamy kontroli i nie ponosimy odpowiedzialności za politykę prywatności i praktyki
                    osób trzecich.
                </p>
                <h2  className="rodo__h2">Ciasteczka</h2>
                <p className="rodo__text">
                    Nasza strona internetowa korzysta z plików cookie. Plik cookie to plik zawierający identyfikator (ciąg liter i cyfr),
                    który jest wysyłany przez serwer internetowy do przeglądarki internetowej i przechowywany przez przeglądarkę.
                    Dane identyfikacyjne są ponownie przesyłane na serwer za każdym razem, gdy przeglądarka wyśle żądanie otwarcia
                    strony znajdującej się na serwerze. Sesyjny plik cookie wygasa z końcem sesji użytkownika, gdy przeglądarka
                    internetowa jest zamykana. Pliki cookie zazwyczaj nie zawierają żadnych informacji identyfikujących użytkownika,
                    ale dane osobowe, które przechowujemy na Twój temat, mogą być powiązane z informacjami przechowywanymi w plikach
                    cookie i uzyskiwanymi z nich.
                </p>
                <p className="rodo__text">
                    Większość przeglądarek pozwala odmówić przyjęcia plików cookie:
                </p>
                <ol className="rodo__list">
                    <li>
                        W przeglądarce Internet Explorer (wersja 10) można blokować pliki cookie, korzystając z dostępnych ustawień
                        zastępowania obsługi plików cookie, klikając „Narzędzia”, „Opcje internetowe”, „Prywatność”, a następnie
                        „Zaawansowane”;
                    </li>
                    <li>
                        W przeglądarce Firefox (wersja 24) możesz zablokować wszystkie pliki cookie, klikając „Narzędzia”, „Opcje”,
                        „Prywatność”, wybierając „Użyj ustawień niestandardowych dla historii” z menu rozwijanego i odznaczając
                        „Akceptuj ciasteczka z witryn”.
                    </li>
                    <li>
                        W Chrome (wersja 29) możesz zablokować wszystkie pliki cookie, otwierając menu „Dostosuj i kontroluj”,
                        klikając „Ustawienia”, „Pokaż ustawienia zaawansowane” i „Ustawienia treści”, a następnie wybierając
                        „Blokuj witrynom ustawianie dowolnych danych” pod nagłówkiem „Pliki cookie”.
                    </li>
                </ol>
            </div>
            <Footer />
        </>
    )
}

export default Rodo