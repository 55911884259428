import React from "react";

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <>
      <div className="footer">
        <p>
          Copyright © {year} Violetta Borowska<span></span>
        </p>
      </div>
    </>
  );
};

export default Footer;
