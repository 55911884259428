import Bettelheim from "../components/Bettelheim";
import Violetta from "../components/Violetta";
import Stemalczyk from "../components/Stemalczyk";
import Jung from "../components/Jung";
import Campbell from "../components/Campbell";
import Tuszyńska from "../components/Tuszyńska";
import CarrGomm from "../components/CarrGomm";
import ViolettaBorowska from "./../assets/Magis_ViolettaB_08.jpg";

const About = ({ refAbout }) => {
  return (
    <div ref={refAbout}>
      <div className="about">
        <Bettelheim />
        <Violetta />
        <hr className="hr" />
        <Stemalczyk />
        <div className="about__text">
          <p>
            Człowiek jest moją pasją, a szczególnie to co w nim znajduje się na
            styku duchowości i psychologii. Także praca z człowiekiem,
            poznawanie go, pomoc w rozwiązywaniu jego dylematów. Następnie bycie
            przy nim w stawaniu się coraz bardziej sobą, w poznawaniu swoich
            ograniczeń, ale też zasobów. Bowiem proces psychoterapii jest bardzo
            skuteczną formą pracy własnej, przekraczaniem siebie, zmianą życia
            na lepsze, a to wszystko jest nie do przecenienia.
          </p>
        </div>
        <Jung />
        <div className="about__text">
          <p>
            Często jedyną słuszną decyzją w zakresie pozbycia się depresji czy
            lęku jest podjęcie psychoterapii. Proces terapeutyczny jest też
            pewną bezpieczną formą zniwelowania cierpienia w różnego rodzaju
            zaburzeniach osobowości. Terapia służy również tym, którzy nie radzą
            sobie w codziennym życiu z relacjami czy też w komunikacji z
            bliskimi osobami lub w szerszym zakresie. Wiem że warto zadbać o
            siebie i przepracować to co nas uwstecznia lub zatrzymuje w rozwoju
            osobistym, emocjonalnym, a także odbiera nam poczucie sprawczości
            oraz własnej wartości przez nawykowe pomijanie swoich potrzeb.
          </p>
        </div>
        <Campbell />
        <div className="about__text">
          <p>
            W pracy bliskie mi jest podejście ISTDP (Krótkoterminowa Intensywna
            Terapia Psychodynamiczna), jednak często podczas terapii pracuję
            eklektycznie wykorzystując inne podejścia psychoterapeutyczne, takie
            jak terapia systemowa, schematów czy elementy terapii
            poznawczo-behawioralnej. Integratywny, a co za tym idzie, elastyczny
            dobór odpowiedniego podejścia do pracy z pacjentem, buduje
            przestrzeń bezpieczną i skuteczną.
          </p>
        </div>
        <Tuszyńska />
        <div className="about__text">
          <p>
            Ulgę w cierpieniu, bólu może przynieść wewnętrzna praca nad sobą, z
            towarzyszącą osobą. Zapraszam Państwa do skorzystania z procesu
            psychoterapii, do tej podróży we dwoje w sytuacji kryzysu,
            cierpienia lub gdy czujecie potrzebę zmiany w kierunku dobrego i
            szczęśliwego życia.
          </p>
        </div>
        <CarrGomm />
        <div className="about__text">
          <p>
            Prywatnie od ponad trzydziestu lat jestem żoną, mamy pięcioro
            dzieci, w tym czworo dorosłych i jedną młodszą córkę.
          </p>
        </div>
        <hr className="hr margin-10" />
        <div className="about__img">
          <img src={ViolettaBorowska} alt="img" />
        </div>
        <hr className="hr" />
      </div>
    </div>
  );
};

export default About;
