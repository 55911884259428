import React from "react";

const Bettelheim = () => {
  return (
    <div className="quote">
      <hr className="hr-top" />
      <p>
        Odwracając spojrzenie od świata zewnętrznego i zwracając je ku wnętrzu -
        ku wewnętrznej naturze rzeczy - zyskamy prawdziwą wiedzę i zdołamy
        zrozumieć to, co ukryte, a co należy poznać.
      </p>
      <hr className="hr-bottom" />
      <span className="author">B. Bettelheim</span>
    </div>
  );
};

export default Bettelheim;
