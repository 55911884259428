import React from "react";

const Tuszyńska = () => {
  return (
    <div className="quote">
      <hr className="hr-top" />
      <p>
        <p>Jesteśmy pamięcią. Tym co pamiętamy.</p>
        <p>I tym, co o nas pamiętają inni.</p>
        <p>Coraz częściej myślę, że bardziej jeszcze jesteśmy niepamięcią.</p>
        <p>Tym, co zapominamy,</p>
        <p>
          Co w geście samoobrony wymazujemy z pamięci, wypieramy ze świadomości,
          omijamy w myślach.
        </p>
        <p>
          Unieważniamy, żeby było łatwiej lub lżej ,żeby nie bolało albo nie
          przypominało bólu.
        </p>
        <hr className="hr-bottom" />
        <span className="author">Agata Tuszyńska</span>
      </p>
    </div>
  );
};

export default Tuszyńska;
