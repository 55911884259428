import React from "react";

const Header = ({ refHome, refAbout, refContact }) => {
  const ScrollTo = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="header">
        <p onClick={() => ScrollTo(refHome)}>Strona Główna</p>
        <p className="big__size">|</p>
        <p onClick={() => ScrollTo(refAbout)}>O Mnie</p>
        <p className="big__size">|</p>
        <p onClick={() => ScrollTo(refContact)}>Kontakt</p>
      </div>
    </>
  );
};

export default Header;
