import { ExternalLink } from "react-external-link";
import { ImLocation } from "react-icons/im";
import { GrMail } from "react-icons/gr";
import { BsFillTelephoneFill, BsFacebook } from "react-icons/bs";
import Flowers from "./../assets/flowers.png";

const Contact = ({ refContact }) => {
  return (
    <div className="kontakt" ref={refContact}>
      <img src={Flowers} alt="kwiaty" className="kontakt__flowers" />
      <h1 className="kontakt__title">Kontakt</h1>
      <hr className="home__hr" />
      <ul className="kontakt__list">
        <li>
          <ExternalLink
            href="https://www.google.com/maps/place/Fryderyka+Chopina+36,+80-272+Gda%C5%84sk/@54.386265,18.5795623,17z/data=!3m1!4b1!4m5!3m4!1s0x46fd74dde49d463d:0x5e398cb375216d37!8m2!3d54.386265!4d18.581751"
            className="kontakt__link"
          >
            <ImLocation className="list__img" />
            <p>ul. Fryderyka Chopina 36/2 Gdańsk Strzyża</p>
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="mailto:papilon15@wp.pl" className="kontakt__link">
            <GrMail className="list__img" />
            <p>papilon15@wp.pl</p>
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="tel:513008134" className="kontakt__link">
            <BsFillTelephoneFill className="list__img" />
            <p>+48 513 008 134</p>
          </ExternalLink>
        </li>
        <li>
          <ExternalLink
            href="https://www.facebook.com/wioletta.borowska.31"
            className="kontakt__link"
          >
            <BsFacebook className="list__img" />
            <p>Wioletta Borowska</p>
          </ExternalLink>
        </li>
      </ul>
    </div>
  );
};
export default Contact;
