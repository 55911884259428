import React from "react";

const Jung = () => {
  return (
    <div className="quote">
      <p>
        Kiedy wewnętrzna sytuacja nie jest uświadomiona, przemienia się w los.
      </p>
      <span className="author">C. G. Jung</span>
    </div>
  );
};

export default Jung;
