import React from "react";
import "./styles/themes/theme.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ReactDOM from "react-dom";
import Rodo from "./pages/Rodo";
import NotFound from "./pages/NotFound";

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="Rodo" element={<Rodo />} />
            <Route path="*" element={NotFound} />
          </Route>
        </Routes>
      </Router>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
