import React from "react";

const Campbell = () => {
  return (
    <div className="quote">
      <p>
        Jaskinia ,do której najbardziej boisz się wejść, staje się żródłem,
        którego poszukujesz.
      </p>
      <span className="author">Joseph Campbell </span>
    </div>
  );
};

export default Campbell;
