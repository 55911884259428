import React from "react";

const CarrGomm = () => {
  return (
    <div className="quote">
      <p className="quote__background">
        Pieśni naszych przodków to także pieśni naszych dzieci.
      </p>
      <span className="author">Philip Carr-Gomm</span>
    </div>
  );
};

export default CarrGomm;
