import React from "react";
import Img from "./../assets/Magis_ViolettaB_07.jpg";

const Violetta = () => {
  return (
    <div className="flex wrap">
      <div className="text col-6 col-12">
        <p>
          Nazywam się Violetta Borowska. Z wykształcenia jestem nauczycielem
          (Studium Nauczycielskie w Świdnicy), teologiem (Gdański Instytut
          Teologiczny - filia Katolickiego Uniwersytetu Lubelskiego), a przede
          wszystkim psychoterapeutą. Ukończyłam roczne Studium Terapii
          Systemowej Rodzin we Wrocławiu, roczny Kurs Terapii Integralnej oraz
          czteroletnie Studium Psychoterapii przy Stowarzyszeniu Psychologów
          Chrześcijańskich w Warszawie. Ciągły rozwój jest dla mnie ważny,
          dlatego nadal poszerzam swoje umiejętności psychoterapeutyczne, w
          związku z tym jestem w trakcie trzyletniej szkoły (Core Trainig), w
          podejściu ISTDP oraz dwuletnim szkoleniu z Psychotraumatologii
          Praktycznej. Swoją pracę poddaję regularnym superwizjom.
        </p>
      </div>
      <div className="about__profil">
        <img src={Img} alt="Violetta Borowska" />
      </div>
    </div>
  );
};

export default Violetta;
